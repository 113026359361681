export default ({
    namespaced: true,
    state: {
        allEvents:"",//所有普通事件
        staffs:"",//事件人员配置
        eventsSelect:"",//选择事件列表
        staffPart:"",//人员配置参加数据
        dockingPart:"",//机构对接参加数据
        partTickets:"",//投票参与情况
        eventCommonts:"",//事件评论
        dianzan:"",//事件点赞
        collect:"",//事件收藏

    },
    getters: {

    },
    mutations: {
        allEventsMuta(state:any,allEvents:any){
            state.allEvents = allEvents
        },
        eventStaffsMuta(state:any,eventStaffs:any){
            state.staffs = eventStaffs
        },
        eventsSelectMuta(state:any,eventsSelect:any){
            state.eventsSelect = eventsSelect
        },
        eventStaffPartMuta(state:any,eventStaffPart:any){
            state.staffPart = eventStaffPart
        },
        eventDockingPartMuta(state:any,eventDockingPart:any){
            state.dockingPart = eventDockingPart
        },
        partTicketDataMuta(state:any,partTicketData:any){
            state.partTickets = partTicketData
        },
        commontDataMuta(state:any,commontData:any){
            state.eventCommonts = commontData
        },
        dianzanMuta(state:any,dianzan:any){
            state.dianzan = dianzan
        },
        collectMuta(state:any,collect:any){
            state.collect = collect
        },
        guanzhuMuta(state:any,guanzhu:any){
            state.guanzhu = guanzhu
        }


    },
    actions: {
        allEventsDispatch(context:any,allEvents:any){
            context.commit("allEventsMuta",allEvents)
        },
        eventStaffsDispatch(context:any,eventStaffs:any){
            context.commit("eventStaffsMuta",eventStaffs)
        },
        eventsSelectDispatch(context:any,eventsSelect:any){
            context.commit("eventsSelectMuta",eventsSelect)
        },
        eventStaffPartDispatch(context:any,eventStaffPart:any){
            context.commit("eventStaffPartMuta",eventStaffPart)
        },
        eventDockingPartDispatch(context:any,eventDockingPart:any){
            context.commit("eventDockingPartMuta",eventDockingPart)

        },
        partTicketDataDispatch(context:any,partTicketData:any){
            context.commit("partTicketDataMuta",partTicketData)
        },
        commontDataDispatch(context:any,commontData:any){
            context.commit("commontDataMuta",commontData)
        },
        dianzanDispatch(context:any,dianzan:any){
            context.commit("dianzanMuta",dianzan)
        },
        collectDispatch(context:any,collect:any){
            context.commit("collectMuta",collect)
        },
        guanzhuDispatch(context:any,guanzhu:any){
            context.commit("guanzhuMuta",guanzhu)
        }



    },


    modules: {

    }
})
