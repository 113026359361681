export default ({
    namespaced: true,
    state: {
        allFast:"",//全部快速事件

    },
    getters: {

    },
    mutations: {
        allFastEventsMuta(state:any,allFastEvents:unknown){
            state.allFast = allFastEvents
        }

    },
    actions: {
        allFastEventsDispatch(context:any,allFastEvents:unknown){
            context.commit("allFastEventsMuta",allFastEvents)
        }



    },


    modules: {

    }
})
