export default ({
    namespaced: true,
    state: {
        articlesList:"",//所有文章信息

    },
    getters: {

    },
    mutations: {
        articleDataMuta(state:any,articleData:any){
            state.articlesList = articleData
        }
    },
    actions: {
        articleDataDispatch(context:any,articleData:any){
            context.commit("articleDataMuta",articleData)
        }



    },


    modules: {

    }
})
