import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd, {message} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import "./rem.ts"
import VueLazyLoad from 'vue-lazyload';

createApp(App).use(store).use(router).use(Antd).use(VueLazyLoad).mount('#app')

router.beforeEach((to,from,next)=>{
    if(to.name !== "Home"){
        next()
    }else if(localStorage.getItem("hflag") == "logquest" ||localStorage.getItem("hflag") == "quanxian" || localStorage.getItem("hflag") == "segment" || localStorage.getItem("hflag") == "duanwei" || localStorage.getItem("hflag") == "zongbian"){
        next()
    } else {


        message.warning("您还没有登录，请先登录！")
        router.push("/login")
    }



})
