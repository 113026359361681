export default ({
    namespaced: true,
    state: {
        eventTickets:"",//事件投票信息

    },
    getters: {

    },
    mutations: {
        innerTicketMuta(state:any,eventTicketData:unknown){
            state.eventTickets = eventTicketData
        }


    },
    actions: {

        innerTicketDispatch(context:any,eventTicketData:unknown){
            context.commit("innerTicketMuta",eventTicketData)
        }


    },


    modules: {

    }
})
