import { createStore } from 'vuex'
import user from "./user"
import innerEvent from "@/store/innerEvent";
import innerFastEvent from "@/store/innerFastEvent";
import innerTicket from "@/store/innerTicket"
import customer from "@/store/customer";
import article from "@/store/article";
import innerGoods from "@/store/innerGoods";


export default createStore({
  state: {
    username:"",
    backFlg:"",
    goods:"",
    order:"",
    user:"",
    rem:"",
    news:"",
    active:"",
    specials:"",
    seckillA:"",
    seckillB:"",
    seckillC:"",
    wifi:"",
    G4:"",
    low:"",
    monitor:"",
    coordinates:"",
    panels:"",
    surroundings:"",
    promotion:"",
    deUser:"",
    first:"",
    level:"",
    admin:"",
    pc:"",
    col:"",
    personal:"",
    ev:"",
    evRe:"",
    deGoods:"",
    manyImg:"",
    couponGive:"",
    artCom:"",
    detailDoc:"",
    detailData:"",
    nopay:""

  },
  getters:{
    getbackFlg(state){
      return state.backFlg
    },
    getUserName(state){
      return state.username
    }


  },
  mutations: {
    upuser(state,userName){
      state.username = userName

    },
    upbFlg(state,bFlg){
      state.backFlg = bFlg

    },
    updateAllGoods(state,allGoods){
      state.goods = allGoods
    },
    updateOrder(state,orderData){
       state.order = orderData
    },
    updateUser(state,userData){
      state.user = userData
    },
    updateRemData(state,remData){
      state.rem = remData
    },
    updateNewData(state,newData){
      state.news = newData
    },
    updateActiveData(state,activeData){
      state.active = activeData
    },
    updateSpecialsData(state,specialsData){
      state.specials = specialsData
    },
    updateSpecialsDataA(state,seckillDataA){
      state.seckillA = seckillDataA
    },
    updateSpecialsDataB(state,seckillDataB){
      state.seckillB = seckillDataB
    },
    updateSpecialsDataC(state,seckillDataC){
      state.seckillC = seckillDataC
    },
    updateCameraData(state,cameraData){
      state.wifi = cameraData
    },
    updateCameraData4G(state,cameraData4G){
      state.G4 = cameraData4G
    },
    updateCameraDataLow(state,cameraDataLow){
      state.low = cameraDataLow
    },
    updateCameraDataMo(state,cameraDataMo){
      state.monitor = cameraDataMo
    },
    updateCameraDataCo(state,cameraDataCo){
      state.coordinates = cameraDataCo
    },
    updateCameraDataPa(state,cameraDataPa){
      state.panels = cameraDataPa
    },
    updateCameraDataSu(state,cameraDataSu){
      state.surroundings = cameraDataSu
    },
    updateCameraDataPr(state,cameraDataPr){
      state.promotion = cameraDataPr
    },
    updateDeUser(state,userDe){
      state.deUser = userDe
    },
    updatePc(state,pc){
      state.first = pc
    },
    updateLevel(state,levelFlag){
      state.level = levelFlag
    },
    updateAdminData(state,adminData){
      state.admin = adminData
    },
    updatePcData(state,pcData){
      state.pc = pcData
    },
    updateColData(state,colData){
      state.col = colData
    },
    updatePerson(state,personal){
      state.personal = personal
    },
    updateEv(state,evaluation){
      state.ev = evaluation
    },
    updateEvRe(state,evaluationRe){
      state.evRe = evaluationRe
    },
    updateDeleteGoods(state,deleteGoods){
      state.deGoods = deleteGoods
    },
    updatemanyImg(state,imgData){
      state.manyImg = imgData
    },
    updateCouponGive(state,couponGiveData){
      state.couponGive = couponGiveData

    },
    updateContont(state,contont){
      state.artCom = contont
    },
    updateGoodDetailDoc(state,goodDetailDoc){
      state.detailDoc = goodDetailDoc
    },
    updateDetailDa(state,detailDa){
      state.detailData = detailDa
    },
    updateNoOrderData(state,noOrderData){
      state.nopay = noOrderData
    }



  },
  actions: {
    disuser(context, userName){
      context.commit("upuser",userName)

    },
    disbFlg(context,bFlg){
      context.commit("upbFlg",bFlg)
    },


  },
  modules: {
    user,
    innerEvent,
    innerFastEvent,
    innerTicket,
    customer,
    article,
    innerGoods
  }
})
