export default ({
    namespaced: true,
    state: {
        url:"",//商品封面图
        url2:"",//商品第一张详情图
        url3:"",//商品第二张详情图
        goods:"",//商品列表
        goodsAfterData:"",//商品售后
        applicationData:"",//商铺申请
        contont:"",//商品详情
    },
    getters: {

    },
    mutations: {
        urlMuta(state:any,url:any){
            state.url = url
        },
        url2Muta(state:any,url2:any){
            state.url2 = url2
        },
        url3Muta(state:any,url3:any){
            state.url3 = url3
        },
        goodsDataMuta(state:any,goodsData:any){
            state.goods = goodsData
        },
        goodsAfterDataMuta(state:any,goodsAfterData:any){
          state.goodsAfterData = goodsAfterData
        },
        applicationDataMuta(state:any,applicationData:any){
            state.applicationData = applicationData
        },
        contontMuta(state:any,contont:any){
            state.contont = contont
        }
    },
    actions: {
        urlDispatch(context:any,url:any){
            context.commit("urlMuta",url)
        },
        url2Dispatch(context:any,url2:any){
            context.commit("url2Muta",url2)
        },
        url3Dispatch(context:any,url3:any){
            context.commit("url3Muta",url3)
        },
        goodsDataDispatch(context:any,goodsData:any){
            context.commit("goodsDataMuta",goodsData)
        },
        goodsAfterDataDispatch(context:any,goodsAfterData:any){
            context.commit("goodsAfterDataMuta",goodsAfterData)
        },
        applicationDataDispatch(context:any,applicationData:any){
            context.commit("applicationDataMuta",applicationData)
        },
        contontDispatch(context:any,contont:any){
            context.commit("contontMuta",contont)
        }



    },


    modules: {

    }
})
