const systemRouter = {

    path: '/systemMessages',
    name: 'SystemMessages',
    component: ()=>import("../components/zxztcommon/SystemMessages.vue")



}

export default systemRouter