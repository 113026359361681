export default ({
    namespaced: true,
    state: {
        customerInfor:"",//网站用户基本数据
        customerDetail:"",//用户详细数据
        indentityData:"",//用户身份认证信息
        educationData:"",//用户学历认证信息
        professionalData:"",//用户职业认证信息
        experienceData:"",//用户经验认证信息
        looksData:"",//用户相貌认证信息
        wealthData:"",//用户财富认证信息
        userSystem:"",//用户系统信息

    },
    getters: {

    },
    mutations: {
        customerMuta(state:any,customerData:any){
            state.customerInfor = customerData
        },
        customerDetailMuta(state:any,customerDetailData:any){
            state.customerDetail = customerDetailData
        },
        identityMuta(state:any,identityList:any){
            state.indentityData = identityList
        },
        educationMuta(state:any,educationList:any){
            state.educationData = educationList
        },
        professionalMuta(state:any,professionalList:any){
            state.professionalData = professionalList
        },
        experienceMuta(state:any,experienceList:any){
            state.experienceData = experienceList
        },
        looksMuta(state:any,looksList:any){
            state.looksData = looksList

        },
        wealthMuta(state:any,wealthList:any){
            state.wealthData = wealthList
        },
        customerSystemMuta(state:any,customerSystem:any){
            state.userSystem = customerSystem
        }

    },
    actions: {

        customerDispatch(context:any,customerData:any){
            context.commit("customerMuta",customerData)
        },
        customerDetailDispatch(context:any,customerDetailData:any){
            context.commit("customerDetailMuta",customerDetailData)
        },
        identityDispatch(context:any,identityList:any){
            context.commit("identityMuta",identityList)
        },
        educationDispatch(context:any,educationList:any){
            context.commit("educationMuta",educationList)
        },
        professionalDispatch(context:any,professionalList:any){
            context.commit("professionalMuta",professionalList)
        },
        experienceDispatch(context:any,experienceList:any){
            context.commit("experienceMuta",experienceList)
        },
        looksDispatch(context:any,looksList:any){
            context.commit("looksMuta",looksList)
        },
        wealthDispatch(context:any,wealthList:any){
            context.commit("wealthMuta",wealthList)
        },
        customerSystemDispatch(context:any,customerSystem:any){
            context.commit("customerSystemMuta",customerSystem)
        }


    },


    modules: {

    }
})
