

const userRouter ={

    path: '/userList',
    name: 'UserList',
    component: ()=>import("../components/zxztcommon/UserList.vue"),


}
export default userRouter