import {createRouter, createWebHistory, onBeforeRouteUpdate, createWebHashHistory,RouteRecordRaw} from 'vue-router'
import Home from '../views/Home.vue'

import systemRouter from "@/router/systemRouter";

import userRouter from "@/router/userRouter";

import ticketRouter from "@/router/ticketRouter";

import custimerRouter from "@/router/custimerRouter";

import fastEventRouter from "@/router/fastEventRouter";

import articleRouter from "@/router/articleRouter";
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name:"index",
    redirect:"/login"
  },
  {
    path: '/home',
    name: 'Home',
    redirect:"/basic",
    component: Home,
    meta:{
      logquest:true,//一级
      quanxian:true,//二级
      segment:true,//三级
      duanwei:true,//四级
      zongbian:true, //五级
      requireAuth: true
    },

    children:[
        {
          path: '/basic',
          name: 'Basic',
          component: ()=>import("../components/zxztcommon/Basic.vue")
    },

      {
        path: '/passEventList',
        name: 'PassEventList',
        component: ()=>import("../components/zxztcommon/PassEventList.vue")
      },
      {
        path: '/deleteEventList',
        name: 'DeleteEventList',
        component: ()=>import("../components/zxztcommon/DeleteEventList.vue")
      },
      {
        path: '/stopEventList',
        name: 'StopEventList',
        component: ()=>import("../components/zxztcommon/StopEventList.vue")
      },

      {
        path: '/staffDetail',
        name: 'StaffDetail',
        component: ()=>import("../components/zxztcore/StaffDetail.vue")
      },
      {
        path: '/modifyEvent',
        name: 'ModifyEvent',
        component: ()=>import("../components/zxztcore/ModifyEvent.vue")
      },
      {
        path: '/eventDetail',
        name: 'EventDetail',
        component: ()=>import("../components/zxztcore/EventDetail.vue")
      },
      {
        path: '/modifyFastEvent',
        name: 'ModifyFastEvent',
        component: ()=>import("../components/zxztcore/ModifyFastEvent.vue")
      },
      {
        path: '/passFastEventList',
        name: 'PassFastEventList',
        component: ()=>import("../components/zxztcommon/PassFastEventList.vue")
      },
      {
        path: '/deleteFastEventList',
        name: 'DeleteFastEventList',
        component: ()=>import("../components/zxztcommon/DeleteFastEventList.vue")
      },
      {
        path: '/stopFastEventList',
        name: 'StopFastEventList',
        component: ()=>import("../components/zxztcommon/StopFastEventList.vue")
      },
      {
        path: '/rejectEventList',
        name: 'RejectEventList',
        component: ()=>import("../components/zxztcommon/RejectEventList.vue")
      },
      {
        path: '/rejectFastEventList',
        name: 'RejectFastEventList',
        component: ()=>import("../components/zxztcommon/RejectFastEventList.vue")
      },
      {
        path: '/rejectArticleList',
        name: 'RejectArticleList',
        component: ()=>import("../components/zxztcommon/RejectArticleList.vue")
      },
      {
        path: '/indexPicture',
        name: 'IndexPicture',
        component: ()=>import("../components/zxztcore/IndexPicture.vue")
      },
      {
        path: '/indexPictureList',
        name: 'IndexPictureList',
        component: ()=>import("../components/zxztcommon/IndexPctureList.vue")
      },
      {
        path: '/indexEventList',
        name: 'IndexEventList',
        component: ()=>import("../components/zxztcommon/IndexEventList.vue")
      },
      {
        path: '/indexFastEventList',
        name: 'IndexFastEventList',
        component: ()=>import("../components/zxztcommon/IndexFastEventList.vue")
      },
      {
        path: '/systemMessageList',
        name: 'SystemMessageList',
        component: ()=>import("../components/zxztcommon/SystemMessageList.vue")
      },

      {
        path: '/article',
        name: 'Article',
        component: ()=>import("../components/zxztcommon/Article.vue")
      },

      {
        path: '/articleDetail',
        name: 'ArticleDetail',
        component: ()=>import("../components/zxztcore/ArticleDetail.vue")
      },
      {
        path: '/modifyArticle',
        name: 'ModifyArticle',
        component: ()=>import("../components/zxztcore/ModifyArticle.vue")
      },
      {
        path: '/editor',
        name: 'Editor',
        component: ()=>import("../components/zxztcommon/Editor.vue")
      },

      {
        path: '/customerDetail',
        name: 'CustomerDetail',
        component: ()=>import("../components/zxztcore/CustomerDetail.vue")
      },
      {
        path: '/identityAuthList',
        name: 'IdentityAuthList',
        component: ()=>import("../components/zxztcore/IdentityAuthList.vue")
      },
      {
        path: '/educationAuthList',
        name: 'EducationAuthList',
        component: ()=>import("../components/zxztcore/EducationAuthList.vue")
      },
      {
        path: '/professionalAuthList',
        name: 'ProfessionalAuthList',
        component: ()=>import("../components/zxztcore/ProfessionalAuthList.vue")
      },
      {
        path: '/experienceAuthList',
        name: 'ExperienceAuthList',
        component: ()=>import("../components/zxztcore/ExperienceAuthList.vue")
      },
      {
        path: '/looksAuthList',
        name: 'LooksAuthList',
        component: ()=>import("../components/zxztcore/LooksAuthList.vue")
      },
      {
        path: '/wealthAuthList',
        name: 'WealthAuthList',
        component: ()=>import("../components/zxztcore/WealthAuthList.vue")
      },
      {
        path: '/deleteCustomerList',
        name: 'DeleteCustomerList',
        component: ()=>import("../components/zxztcommon/DeleteCustomerList.vue")
      },
      {
        path: '/user',
        name: 'User',
        component: ()=>import("../components/zxztcommon/User.vue"),

      },
      {
        path: '/fundList',
        name: 'FundList',
        component: ()=>import("../components/zxztcommon/FundList.vue"),

      },
      {
        path: '/frozenUser',
        name: 'FrozenUser',
        component: ()=>import("../components/zxztcore/FrozenUser.vue")
      },
      {
        path: '/press',
        name: 'Press',
        component: ()=>import("../components/zxztcommon/Press.vue")
      },

      {
        path: '/personalInfor',
        name: 'PersonalInfor',
        component: ()=>import("../views/PersonalInfor.vue")
      },
      {
        path: '/level',
        name: 'Level',
        component: ()=>import("../components/zxztcommon/Level.vue")
      },
      {
        path: '/personal',
        name: 'Personal',
        component: ()=>import("../components/zxztcommon/Personal.vue")
      },
      {
        path: '/freezeUser',
        name: 'FreezeUser',
        component: ()=>import("../components/zxztcommon/FreezeUser.vue")
      },
      {
        path: '/admin',
        name: 'Admin',
        component: ()=>import("../components/zxztcommon/Admin.vue")
      },
      {
        path: '/eventTicketList',
        name: 'EventTicketList',
        component: ()=>import("../components/zxztcore/EventTicketList.vue")
      },
      {
        path: '/passArticleList',
        name: 'PassArticleList',
        component: ()=>import("../components/zxztcommon/PassArticleList.vue")
      },
      {
        path: '/deleteArticleList',
        name: 'DeleteArticleList',
        component: ()=>import("../components/zxztcommon/DeleteArticleList.vue")
      },
      {
        path: '/eventList',
        name: 'EventList',
        component: ()=>import("../components/zxztcommon/EventList.vue"),

      },

      userRouter,systemRouter,ticketRouter, systemRouter,custimerRouter,fastEventRouter,articleRouter,


      {
        path: '/columnManager',
        name: 'ColumnManager',
        component: ()=>import("../components/zxztcommon/ColumnManager.vue")
      },
      {
        path: '/busenissList',
        name: 'BusenissList',
        component: ()=>import("../components/zxztcommon/BusenissList.vue")
      },
      {
        path: '/busenissDetail',
        name: 'BusenissDetail',
        component: ()=>import("../components/zxztcore/BusenissDetail.vue")
      },
      {
        path: '/deleteBusenissList',
        name: 'DeleteBusenissList',
        component: ()=>import("../components/zxztcommon/DeleteBusenissList.vue")
      },
      {
        path: '/dockingDetail',
        name: 'DockingDetail',
        component: ()=>import("../components/zxztcore/DockingDetail.vue")
      },
      {
        path: '/partDetail',
        name: 'PartDetail',
        component: ()=>import("../components/zxztcore/PartDetail.vue")
      },
      {
        path: '/partDockingDetail',
        name: 'PartDockingDetail',
        component: ()=>import("../components/zxztcore/PartDockingDetail.vue")
      },
      {
        path: '/commontdetail',
        name: 'Commontdetail',
        component: ()=>import("../components/zxztcore/Commontdetail.vue")
      },
      {
        path: '/partTicketDetail',
        name: 'PartTicketDetail',
        component: ()=>import("../components/zxztcore/PartTicketDetail.vue")
      },
      {
        path: '/userSystemData',
        name: 'UserSystemData',
        component: ()=>import("../components/zxztcore/UserSystemData.vue")
      },
      {
        path: '/userSystemDataUv',
        name: 'UserSystemDataUv',
        component: ()=>import("../components/zxztcore/UserSystemDataUv.vue")
      },
      {
        path: '/busenissIdentityAuthList',
        name: 'BusenissIdentityAuthList',
        component: ()=>import("../components/zxztcommon/BusenissIdentityAuthList.vue")
      },
      {
        path: '/passIdentityAuthList',
        name: 'PassIdentityAuthList',
        component: ()=>import("../components/zxztcommon/PassIdentityAuthList.vue")
      },
      {
        path: '/passEducationList',
        name: 'PassEducationList',
        component: ()=>import("../components/zxztcommon/PassEducationList.vue")
      },
      {
        path: '/passProfessionalAuthList',
        name: 'PassProfessionalAuthList',
        component: ()=>import("../components/zxztcommon/PassProfessionalAuthList.vue")
      },
      {
        path: '/passExperienceAuthList',
        name: 'PassExperienceAuthList',
        component: ()=>import("../components/zxztcommon/PassExperienceAuthList.vue")
      },
      {
        path: '/passLooksAuthList',
        name: 'PassLooksAuthList',
        component: ()=>import("../components/zxztcommon/PassLooksAuthList.vue")
      },
      {
        path: '/explainList',
        name: 'ExplainList',
        component: ()=>import("../components/zxztcommon/ExplainList.vue")
      },
      {
        path: '/busenissFinanceAuthList',
        name: 'BusenissFinanceAuthList',
        component: ()=>import("../components/zxztcommon/BusenissFinanceAuthList.vue")
      },
      {
        path: '/businessCompetitivenessAuthList',
        name: 'BusinessCompetitivenessAuthList',
        component: ()=>import("../components/zxztcommon/BusinessCompetitivenessAuthList.vue")
      },
      {
        path: '/busenissSpecificationAuthList',
        name: 'BusenissSpecificationAuthList',
        component: ()=>import("../components/zxztcommon/BusenissSpecificationAuthList.vue")
      },
      {
        path: '/busenissQualityAuthList',
        name: 'BusenissQualityAuthList',
        component: ()=>import("../components/zxztcommon/BusenissQualityAuthList.vue")
      },
      {
        path: '/busenissSocialAuthList',
        name: 'BusenissSocialAuthList',
        component: ()=>import("../components/zxztcommon/BusenissSocialAuthList.vue")
      },
      {
        path: '/upgradation',
        name: 'Upgradation',
        component: ()=>import("../components/zxztcommon/Upgradation.vue")
      },

      {
        path: '/payList',
        name: 'PayList',
        component: ()=>import("../components/zxztcommon/PayList.vue")
      },
      {
        path: '/agreementList',
        name: 'agreementList',
        component: ()=>import("../components/zxztcommon/agreementList.vue")
      },
      {
        path: '/firstPicture',
        name: 'FirstPicture',
        component: ()=>import("../components/zxztcore/FirstPicture.vue")
      },
      {
        path: '/columnPicture',
        name: 'ColumnPicture',
        component: ()=>import("../components/zxztcommon/ColumnPicture.vue")
      },
      {
        path: '/withdrawalList',
        name: 'WithdrawalList',
        component: ()=>import("../views/shop/WithdrawalList.vue")
      },
      {
        path: '/modifySystemMessages',
        name: 'ModifySystemMessages',
        component: ()=>import("../views/article/ModifySystemMessages.vue")
      },
      {
        path: '/invoiceList',
        name: 'InvoiceList',
        component: ()=>import("../views/shop/InvoiceList.vue")
      },
      {
        path: '/institutionalizeList',
        name: 'InstitutionalizeList',
        component: ()=>import("../views/event/InstitutionalizeList.vue")
      },
      {
        path: '/passInstitutionalizeList',
        name: 'PassInstitutionalizeList',
        component: ()=>import("../views/event/PassInstitutionalizeList.vue")
      },
      {
        path: '/goodsUpload',
        name: 'GoodsUpload',
        component: ()=>import("../views/shop/GoodsUpload.vue")
      },
      {
        path: '/goodsList',
        name: 'GoodsList',
        component: ()=>import("../views/goods/GoodsList.vue")
      },
      {
        path: '/detail',
        name: 'Detail',
        component: ()=>import("../views/goods/Detail.vue")
      },
      {
        path: '/restPassword',
        name: 'RestPassword',
        component: ()=>import("../views/RestPassword.vue")
      },
      {
        path: '/shopApplication',
        name: 'ShopApplication',
        component: ()=>import("../views/goods/ShopApplication.vue")
      },
      {
        path: '/goodAfter',
        name: 'GoodAfter',
        component: ()=>import("../views/goods/GoodAfter.vue")
      },

      {
        path: '/orderList',
        name: 'OrderList',
        component: ()=>import("../views/goods/OrderList.vue")
      },
      {
        path: '/modifyGoods',
        name: 'ModifyGoods',
        component: ()=>import("../views/goods/ModifyGoods.vue")
      },
      {
        path: '/modifyUser',
        name: 'ModifyUser',
        component: ()=>import("../views/user/ModifyUser.vue")
      },
      {
        path: '/passTicketList',
        name: 'PassTicketList',
        component: ()=>import("../views/ticket/PassTicketList.vue")
      },
      {
        path: '/passApplicationList',
        name: 'PassApplicationList',
        component: ()=>import("../views/goods/PassApplicationList.vue")
      },
      {
        path: '/detailLooks',
        name: 'DetailLooks',
        component: ()=>import("../views/goods/DetailLooks.vue")
      },
      {
        path: '/recommendArticleList',
        name: 'RecommendArticleList',
        component: ()=>import("../views/article/RecommendArticleList.vue")
      },
      {
        path: '/goodSales',
        name: 'GoodSales',
        component: ()=>import("../views/goods/GoodSales.vue")
      },
      {
        path: '/goodComments',
        name: 'GoodComments',
        component: ()=>import("../views/goods/GoodCommnents.vue")
      },

      {
        path: '/compose',
        name: 'Compose',
        component: ()=>import("../views/article/Compose.vue")
      }

    ]

  },
  {
    path: '/login',
    name: 'Login',
    component: ()=>import("../views/Login.vue")
  },


  {
    path: '/register',
    name: 'Register',
    component: () => import("../views/Register.vue"),
    meta:{
      duanwei:true,
      zongbian:true,
      requireAuth: true
    }

  },
  {
    path: '/newRegister',
    name: 'NewRegister',
    component: () => import("../views/NewRegister.vue"),
    meta: {
      duanwei: true,
      zongbian: true,
      requireAuth: true
    }

  },
  {
    path: '/restPassword',
    name: 'RestPassword',
    component: () => import("../views/RestPassword.vue"),
    meta: {
      duanwei: true,
      zongbian: true,
      requireAuth: true
    }

  },



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes

})





export default router
