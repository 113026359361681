export default ({
    namespaced: true,
    state: {
        userData:"",//后台用户信息
        allUsers:"",//所有后台用户信息
        allDeletedUser:"",//所有后台冻结用户信息

    },
    getters: {

    },
    mutations: {
        userMuta(state:any,user:any){
            state.userData = user
        },
        allUsersMuta(state:any,allUsers:any){
            state.allUsers = allUsers
        },
        allDeletedUserMuta(state:any,allDeletedUsersList:any){
            state.allDeletedUser = allDeletedUsersList
        }


    },
    actions: {
        userDispatch(context:any,user:any){
            context.commit("userMuta",user)
        },
        allUsersDispatch(context:any,allUsers:any){
            context.commit("allUsersMuta",allUsers)
        },
        allDeletedUserDispatch(context:any,allDeletedUsersList:any){
            context.commit("allDeletedUserMuta",allDeletedUsersList)
        }



    },


    modules: {

    }
})
